.course-sidebar {
    background: #000000;
    color: #333;
    width:100%;
    max-width: 260px;
    height: 100vh;
    overflow-y: auto;

    &.hidden {
        transform: translateX(-100%); // Move the sidebar out of view
    }

    &::-webkit-scrollbar {
        width: 5px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Color of the track */
    }

    &::-webkit-scrollbar-thumb {
        background: #888; /* Color of the scrollbar itself */
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color when hovered */
    }

    .course-content-sidebar-container {
        width: 100%;
        // min-height: 100vh;

        .logo{
            display: none;
        }
        
        .module-toggle {
            display: block;
            width: 100%;
            max-width: 260px;

            // background-color: #e7e7e7;
            color: white;
            padding: 10px;
            margin-bottom: 5px;
            border: none;
            text-align: left;
            cursor: pointer;
            // background: rgba(29, 29, 29, 0.48);
            // backdrop-filter: blur(12.5px);
            background: linear-gradient(180deg, #0041E6 0%, #0D44CF 53.5%);
            // background: linear-gradient(180deg, rgba(0, 32, 112, 0.20) 0%, rgba(8, 49, 151, 0.20) 100%), rgba(29, 29, 29, 0.17);
            backdrop-filter: blur(12.5px);
            color: #FFF;
            font-family: 'SF-Pro-Text-Regular';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.48px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:focus {
                outline: none;
            }
        }

        .module-content {
            margin-top: -5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            // margin-left: 20px;
            // margin-bottom: 10px;
            // gap:5px;
            width: 100%;
            // padding:4px 0;
            // `display: none;` and `&.active` removed because visibility is handled by React

            .content-item {
                width:92%;
                // margin-bottom: 5px;
                display: flex;
                align-items: center;
                justify-content: start;
                // gap:10px;
                color: #C9C9C9;
                padding:10px 0 10px 20px;
                cursor: pointer;
                border-bottom: 1px solid #555;
                

                input[type="checkbox"] {
                    margin-right: 10px;
                    // border: none;
                }

                label {
                    cursor: pointer;
                }

                &:hover{
                    background-color:#1D1D1D;
                }
            }
        }
    }
}


@media (max-width:1080px){
    .course-sidebar{
        margin:auto 0;
        min-height: 100vh; /* Full viewport height */
        overflow-y: auto;
        .course-content-sidebar-container{
            // min-height:100vh ;
            .logo{
                background: #000000;
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: start;
                cursor: pointer;
                width: 100%;
                margin-bottom: 10px;
                position: sticky;  // Make the logo sticky
                top: 0;           // Stick to the top of the sidebar
                background: inherit;  // Ensure the logo has the same background as the sidebar for visual consistency
                z-index: 2000;
                .menu-icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color:white;
                    margin-top: -15px;
                }
                .logoimage{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    object-fit: cover;
                    width: 120px;
                    height: 40px;
                    flex-shrink: 0;
                    margin-top: 4px;
                }
            }
            .module-toggle{
                overflow-y: auto;
            }
        }
    }
}