.forgotpassword {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    .container {
        display: flex;
        align-items: center;
        justify-content: start;
        width:100%;
        max-width: 900px;
        min-height: 500px;
        flex-shrink: 0;
        border-radius: 24px;
        border: 1px solid #303030;
        background: rgba(29, 29, 29, 0.17);
        backdrop-filter: blur(12.5px);        
  
    .forgotpassword-left-side {
        min-height: 500px;
        flex: 1; // This will make the left side fill 50% of the .container
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        border-radius: 24px 0px 0px 24px;
       
        .image{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
  
    .forgotpassword-form {
        flex: 1; // This will make the left side fill 50% of the .container
        height:450px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap:20px;

      .forgotpassword-top-section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap:15px;
        flex-direction: column;
  
        .forgotpassword-form-header {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            gap:13px;
            padding: 0 45px; 
            h2{
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.96px;
                color:#fff;
                margin-bottom: 10px;
            }
            span{
                color: #757575;
                font-family: 'SF-Pro-Text-Regular';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // gap:10px;

            .MuiTextField-root {
                margin-bottom: 20px;
    
                .MuiInputLabel-filled {
                    // Targets the label of the filled TextField
                    color: #0041E6; // Default label color
                }
    
                .MuiFilledInput-underline:after {
                    // Targets the underline of the TextField after being filled (focused)
                    border-bottom-color: #0041E6 !important; // Changes the underline color to blue
                }
    
                .MuiFilledInput-input {
                    // Targets the input text itself
                    color: white; // Changes the text color to white
                }
    
                .MuiFilledInput-root {
                    // Targets the root of the filled input
                    background-color: rgba(255, 255, 255, 0.09); // Changes the background color
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.13); // Changes background color on hover
                    }
                    &.Mui-focused {
                        background-color: rgba(255, 255, 255, 0.12); // Changes background color when focused
    
                        .MuiInputLabel-filled {
                            color: #0041E6 !important; // Changes the label color to blue when focused
                        }
                    }
                }
            }

            .reset-button{
                display: flex;
                align-items: center;
                justify-content: center;
                width:340px;
                background: #0041E6;                        color: #000;
                // color: #000;
                height:50px;
                border-radius: 67px;
                font-size: 18px;
                text-decoration: none;
                cursor: pointer;
                border: none;
                color: white;
                font-family: 'SF-Pro-Text-Semibold';
                font-weight: 600;
                .reset-link{
                    color: #FFF;
                    font-size: 18px;
                    text-decoration: none;
                    font-family: 'SF-Pro-Text-Semibold';
        }
                
            }
          }
  
       
      }
      .login-subtitle {
        color: #C9C9C9;
        font-family: 'SF-Pro-Text-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.36px;
        padding: 0 45px; 
            }
    }
  }
}

@media (max-width:1080px){
    .forgotpassword{
        .container{
            max-width : 800px;
        }
    }
}

@media (max-width:900px){
    .forgotpassword{
        .container{
            max-width : 600px;
            margin: 0 20px;
            justify-content: center;
            .forgotpassword-left-side{
                display: none;
            }
            .forgotpassword-form{
                min-height: 380px;
                .forgotpassword-top-section{
                    width:100%;
                    max-width:450px;
                    .forgotpassword-form-header{
                        gap:8px;
                        h2{
                            margin-bottom: 5px;
                        }
                        span{
                            font-size: 16px;
                        }
                    }
                }
                .login-subtitle{
                    width:100%;
                    max-width:380px;
                }
            }
        }
    }
}

@media (max-width:660px){
    .forgotpassword{
        height: 100vh;
        .container{
            max-width : 550px;
            margin: 10px 20px;
            justify-content: center;
            .forgotpassword-left-side{
                display: none;
            }
            .forgotpassword-form{
                min-height: 380px;
                .forgotpassword-top-section{
                    width:100%;
                    max-width:480px;
                    .forgotpassword-form-header{
                        gap:8px;
                        h2{
                            margin-bottom: 5px;
                        }
                        span{
                            font-size: 16px;
                        }
                    }
                }

                .login-subtitle{
                    width:85%;
                    max-width:380px;
                    padding:10px;
                }
            }
        }
    }
}