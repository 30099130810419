@font-face {
  font-family: 'SF-Pro-Text-Ultralight';
  src: url('../public/fonts/SF-Pro-Text-Ultralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-UltralightItalic';
  src: url('../public/fonts/SF-Pro-Text-UltralightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-Thin';
  src: url('../public/fonts/SF-Pro-Text-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-ThinItalic';
  src: url('../public/fonts/SF-Pro-Text-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-Light';
  src: url('../public/fonts/SF-Pro-Text-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-LightItalic';
  src: url('../public/fonts/SF-Pro-Text-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-Regular';
  src: url('../public/fonts/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-RegularItalic';
  src: url('../public/fonts/SF-Pro-Text-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-Medium';
  src: url('../public/fonts/SF-Pro-Text-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-MediumItalic';
  src: url('../public/fonts/SF-Pro-Text-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-Semibold';
  src: url('../public/fonts/SF-Pro-Text-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-SemiboldItalic';
  src: url('../public/fonts/SF-Pro-Text-SemiboldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-Bold';
  src: url('../public/fonts/SF-Pro-Text-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-BoldItalic';
  src: url('../public/fonts/SF-Pro-Text-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-Heavy';
  src: url('../public/fonts/SF-Pro-Text-Heavy.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text-HeavyItalic';
  src: url('../public/fonts/SF-Pro-Text-HeavyItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-Ultralight';
  src: url('../public/fonts/SF-Pro-Display-Ultralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-UltralightItalic';
  src: url('../public/fonts/SF-Pro-Display-UltralightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-Thin';
  src: url('../public/fonts/SF-Pro-Display-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-ThinItalic';
  src: url('../public/fonts/SF-Pro-Display-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-Light';
  src: url('../public/fonts/SF-Pro-Display-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-LightItalic';
  src: url('../public/fonts/SF-Pro-Display-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-Regular';
  src: url('../public/fonts/SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-RegularItalic';
  src: url('../public/fonts/SF-Pro-Display-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-Medium';
  src: url('../public/fonts/SF-Pro-Display-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-MediumItalic';
  src: url('../public/fonts/SF-Pro-Display-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-Semibold';
  src: url('../public/fonts/SF-Pro-Display-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-SemiboldItalic';
  src: url('../public/fonts/SF-Pro-Display-SemiboldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-Bold';
  src: url('../public/fonts/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-BoldItalic';
  src: url('../public/fonts/SF-Pro-Display-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-Heavy';
  src: url('../public/fonts/SF-Pro-Display-Heavy.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Display-HeavyItalic';
  src: url('../public/fonts/SF-Pro-Display-HeavyItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

/* Continue with other font files in a similar manner */




*{
  .link{
      text-decoration:none;
      color:inherit;
  }
}

body,html{
  min-height:100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #000;
}

.app {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  min-height: 100vh; /* Ensure app takes up at least viewport height */
}