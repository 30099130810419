.terms {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
  width:100%;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      width:100%;
      max-width:1170px;  
      margin:0 50px;


      .logoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
  
        .logoImage {
          margin-left: 292px;
          width: 250px;
          height: 250px;
        }
  
        .logoText {
          .maintitle {
            font-size: 100px;
            font-weight: bold;
            color: black; /* Font color set to black */
            font-family: 'Raleway', sans-serif;
            position: relative;
  
            &:after {
              content: attr(data-text);
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              color: black;
              -webkit-text-stroke: 4px black;
              font-size: inherit;
              font-weight: inherit;
              font-family: inherit;
              opacity: 0.4;
            }
          }
        }
      }
  
      .content {
        margin-top: 20px;
        .title {
          font-size: 42px;
          font-family: 'SF-Pro-Text-Semibold';
          background: linear-gradient(271deg, #002070 -3.23%, #5A88FE 107.9%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 600;
        }
        p {
          font-size: 18px;
          margin-bottom: 15px;
          color: white;
          font-family: 'SF-Pro-Text-Regular';
        }
        h2{
          color:white;
          font-family: 'SF-Pro-Text-Regular';
          
        .subtitle{
          color:white;
          font-family: 'SF-Pro-Text-Regular';
        }

        }
        ul{
          color:white;
          font-family: 'SF-Pro-Text-Regular';
          li{
            padding:5px 0;
          }
        }
      }
  
      .connectButton {
        margin-top: 20px;
  
        .connectButtonButton {
          display: flex;
          width: 230px;
          height: 60px;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 67px;
          background: #0041E6;
          cursor: pointer;
          color: #FFF;
          font-family: 'SF-Pro-Text-Semibold';
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.32px;
          border: none;
  
          // &:hover {
          //   color: white;
          //   box-shadow: 0px 0px 15px rgba(254, 84, 0, 0.7);
          // }
        }
  
        .connectButtonLink {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  
    @media screen and (max-width: 1080px) {
      .container {
        .logoContainer {
          .logoImage {
            width: 180px;
            height: 180px;
            margin-left: 0;
          }
  
          .logoText {
            .maintitle {
              font-size: 70px;
            }
          }
        }
  
        .content {
          h1 {
            font-size: 24px;
          }
          p {
            font-size: 16px;
          }
        }
  
        .connectButton {
          .connectButtonButton {
            width: 200px;
            height: 50px;
            font-size: 20px;
          }
        }
      }
    }
  
    @media screen and (max-width: 998px) {
      .container {
        .logoContainer {
          .logoText {
            .maintitle {
              font-size: 60px;
            }
          }
        }
  
        .content {
          h1 {
            font-size: 22px;
          }
          p {
            font-size: 14px;
          }
        }
  
        .connectButton {
          .connectButtonButton {
            width: 180px;
            height: 45px;
            font-size: 18px;
          }
        }
      }
    }
  
    @media screen and (max-width: 660px) {
      .container {
        .logoContainer {
          .logoImage {
            width: 100px;
            height: 100px;
          }
  
          .logoText {
            .maintitle {
              font-size: 40px;
            }
          }
        }
  
        .content {
          h1 {
            font-size: 20px;
          }
          p {
            font-size: 12px;
          }
        }
  
        .connectButton {
          .connectButtonButton {
            width: 180px;
            height: 40px;
            font-size: 16px;
          }
        }
      }
    }
  }