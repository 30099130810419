.testimonials{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 64px;

        h2{
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.96px;
            background: linear-gradient(90deg, #FFF 0%, #6994FF 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin:0;
        }

        .testimonialCards{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap:30px;
        }
    }
    
}

@media (max-width:660px){
    .testimonials{
        .container{
            
            .testimonialCards{
                flex-direction: column;
            }
        }
    }
}