.login{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    .container{
        display: flex;
        align-items: center;
        justify-content: start;
        width:100%;
        max-width: 900px;
        min-height: 500px;
        flex-shrink: 0;
        border-radius: 24px;
        border: 1px solid #303030;
        background: rgba(29, 29, 29, 0.17);
        backdrop-filter: blur(12.5px);
        .login-left-side{
            min-height: 500px;
            flex: 1; // This will make the left side fill 50% of the .container
            display: flex;
            align-items: start;
            justify-content: start;
            border-radius: 24px 0px 0px 24px;
           
            .image{
                width:100%;
                height:100%;
                object-fit: cover;
            }
        }
        .login-form{
            height:450px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            gap:20px;
            width: 100%;
            .login-top-section{
                display: flex;
                align-items: center;
                justify-content: center;
                gap:40px;
                flex-direction: column;
                .login-form-header{
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: start;
                    gap:13px;
                    h2{
                        font-family: 'SF-Pro-Text-Semibold';
                        color: #FFF;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: -0.96px;                        
                        margin-bottom: 8px;
                    }
                    span{
                        color: #757575;
                        font-family: 'SF-Pro-Text-Regular';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        .link{
                            color: #757575;
                            font-family: 'SF-Pro-Text-Regular';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-decoration-line: underline;
                        }
                    }
                }
                .login-buttons{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap:10px;
                    .email-password{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width:340px;
                        background: #0041E6;                        color: #000;
                        color: #000;
                        height:50px;
                        border-radius: 67px;
                        text-decoration: none;
                        .link{
                            color: #FFF;
                            font-size: 18px;
                            text-decoration: none;
                            font-family: 'SF-Pro-Text-Semibold';
                            background-color:transparent;
                            border: none;
                            cursor: pointer;
                        }
                    }
                    // .login-with-google{
                    //     display: flex;
                    //     align-items: center;
                    //     justify-content: center;
                    //     width: 360px;
                    //     height: 50px;
                    //     .custom-google-login-button {
                    //         display: flex;
                    //         align-items: center;
                    //         justify-content: center;
                    //         width: 340px;
                    //         height: 50px;
                    //         border-radius: 24px;
                    //         border: none; // Remove default button border
                    //         background-color: #fff;
                    //         color: #000;
                    //         box-shadow: 0 2px 4px 0 rgba(0,0,0,.25); // Optional: Add shadow to match your design
                    //         cursor: pointer;
                    //         gap: 10px; // To give space between the Google icon and text
                    //         font-size: 18px; // Match the font size of "Continue with Email" button
                    //         font-weight: 500; // Adjust font weight as needed
                    //         font-family: 'SF-Pro-Text-Medium';
                    //         &:hover {
                    //             background-color: #f6f7f8; // Slightly darker background on hover
                    //         }
                    //         .google-img {
                    //             width: 24px; // Adjust as necessary
                    //             height: 24px; // Adjust as necessary
                    //         }
                    //     }
                        
                    // }
                    
                    .login-with-google{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 340px;
                        height: 50px;
                        .custom-google-login-button{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    .sign-in-with-google{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width:340px;
                        background: #0041E6;                        color: #000;
                        color: #000;
                        height:50px;
                        border-radius: 67px;
                        cursor: pointer;
                        .link{
                            color: #FFF;
                            background-color: transparent;
                            border:none;
                            font-size: 18px;
                            text-decoration: none;
                            font-family: 'SF-Pro-Text-Semibold';
                            cursor: pointer;
                        }
    
                    }
    
                }
            }
            .login-subtitle{
                color: #C9C9C9;
                font-family: 'SF-Pro-Text-Regular';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.36px;
                padding: 0 42px;
            }
        }
    }
}

@media (max-width:1080px){
    .login{
        .container{
            max-width : 800px;
        }
    }
}

@media (max-width:900px){
    .login{
        // height: 70vh;
        .container{
            max-width : 600px;
            margin: 0 20px;
            justify-content: center;
            .login-left-side{
                display: none;
            }
            .login-form{
                // display: flex;
                // align-items: center;
                // justify-content: center;
                min-height: 380px;
                .login-subtitle{
                    width:100%;
                    max-width:380px;
                    padding:10px;
                }
            }
        }
    }
}

@media (max-width:660px){
    .login{
        // padding: 20px auto;
        height: 100vh;
        .container{
            width:100%;
            max-width : 500px;
            margin: 10px 0;
            justify-content: center;
            .login-left-side{
                display: none;
            }
            .login-form{
                min-height: 380px;
                .login-top-section{
                    .login-form-header{
                        h2{
                            font-size: 28px;
                        }
                    }
                    .login-buttons{
                        .email-password{
                            width: 300px;
                        }
                    }
                }

                .login-subtitle{
                    width:85%;
                    max-width:380px;
                    padding:10px;
                }
            }
        }
    }
}