.coursecontent{
    margin-top: 20px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap:25px;
        width: 100%;

        .chaptername{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            .name{
                color:white;
                font-size: 18px;
            }
        }

        .video-container.full-screen {
            max-height: 100vh;
          }

        .video-container{
            position: relative;
            width: 90%;
            max-width: 1000px;
            display: flex;
            justify-content: center;
            // margin-inline: auto;
            border-radius: 8px;
            .timeline-container{
                width: 100%;
                height: 3px; // Adjust as needed
                background-color: #ccc; // Background of the timeline
                cursor: pointer;
                position: absolute;
                bottom:48px;
                left: 0;
                z-index:100;
                right: 0;

                .progress-bar {
                   cursor: pointer;
                    height: 100%;
                    background-color: #0041E6; // Progress bar color
                    width: 0%; // Initial width, will be set dynamically

                    &:after { // This is the thumb
                      content: "";
                      position: absolute;
                      right: 0;
                      top: 50%;
                      transform: translateY(-50%) translateX(50%);
                      width: 8px;
                      height: 8px;
                      background-color: #fff;
                      border-radius: 50%;
                      box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
                  }
                }

                .timeline::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: calc(100% - var(--preview-position) * 100%);
                    background-color: rgb(150, 150, 150);
                    display: none;
                  }
                  
                  .timeline::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: calc(100% - var(--progress-position) * 100%);
                    background-color: #0041E6;
                  }
                  
                  .timeline .thumb-indicator {
                    --scale: 0;
                    position: absolute;
                    transform: translateX(-50%) scale(var(--scale));
                    height: 200%;
                    top: -50%;
                    left: calc(var(--progress-position) * 100%);
                    background-color: #0041E6;
                    border-radius: 50%;
                    transition: transform 150ms ease-in-out;
                    aspect-ratio: 1 / 1;
                  }
            }
            video {
              width: 100%;
              border-radius: 8px;
            }
            .video-controls-container{
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                color: white;
                z-index: 100;
                opacity: 1;
                transition: opacity 150ms ease-in-out;
                width:100%;

                .controls{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width:100%;


                    .left-controllers,.right-controllers{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap:8px;
                        padding: 10px 10px 6px 10px;
                    }

                    .right-controllers{
                        .playback-rate-selector{
                            border:none;
                            color:white;
                            border-radius: 8px;
                            border: 1px solid #303030;
                            background: rgba(29, 29, 29, 0.48);
                            backdrop-filter: blur(12.5px);
                            padding:2px;
                            color: #FFF;
                            font-family: 'SF-Pro-Text-Medium';
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -0.48px;
                        }
                        .playback-rate-selector option {
                          background: rgba(29, 29, 29, 0.48);
                          backdrop-filter: blur(12.5px);
                          color: white; /* Change the text color */
                          font-family: 'SF-Pro-Text-Medium'; /* Optionally change font family */
                          font-size: 15px; /* Optionally change font size */
                          font-style: normal; /* Optionally change font style */
                          font-weight: 500; /* Optionally change font weight */
                          line-height: normal; /* Optionally change line height */
                          letter-spacing: -0.48px; /* Optionally change letter spacing */
                      }
                    }
                    .play-pause-btn,.full-screen-btn,.mini-player-btn,.mute-btn svg {
                        fill: white; /* Sets the SVG fill color to white */
                      }
                      
                    button{
                        background: none;
                        border: none;
                        color: inherit;
                        padding: 0;
                        height: 35px;
                        width: 35px;
                        font-size: 1.1rem;
                        cursor: pointer;
                        opacity: .85;
                        transition: opacity 150ms ease-in-out;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    
                    .duration-container {
                        display: flex;
                        align-items: center;
                        gap:4px;
                      }

                    .volume-container {
                        // display: flex;
                        display: inline-block;
                        align-items: center;
                        position: relative;
                        overflow: hidden; // Hide the overflow to initially hide the slider
                        padding-right: 100px;
                      
                        .mute-btn {
                          z-index: 2; // Ensure the button stays clickable by placing it above the slider
                          cursor: pointer;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          background: none;
                          border: none;
                          // padding: 5px;
                        }
                      
                        .volume-slider {
                             -webkit-appearance: none;
                             appearance: none;
                             background: transparent;
                          position: absolute;
                          left: 35px;
                          top: 45%;
                          transform: translateY(-50%) scaleX(0);
                          height: 3px;
                          background: #ccc;
                          border-radius: 2px;
                          cursor: pointer;
                          width: 0;
                          opacity: 0;
                          transition: width 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
                          transition: width 150ms ease-in-out, transform 150ms ease-in-out;
                          transform-origin: left;

                           &:hover,
                            &:focus {
                              width: 100px;
                              opacity: 1;
                              outline: none;
                              transform: translateY(-50%) scaleX(1);
                            }
                          

                            &::-webkit-slider-thumb {
                                -webkit-appearance: none;
                                appearance: none;
                                width: 12px; // Thumb width
                                height: 12px; // Thumb height
                                border-radius: 50%; // Circular thumb
                                background: #0041E6; // Thumb color, adjust as necessary
                                margin-top: -5px;
                                transition: background 0.3s ease;
                            }

                            &::-webkit-slider-runnable-track {
                                width: 100%;
                                height: 3.5px; // Matched with the slider's height for a unified look
                                cursor: pointer;
                                animate: 0.2s;
                                background: linear-gradient(to right, #0041E6 0%, #0041E6 var(--volume-percentage), #ccc var(--volume-percentage), #ccc 100%);                            }
                        }                      
                    }

                    .volume-container:hover .volume-slider,
                    .mute-btn:hover ~ .volume-slider {
                        width: 100px;
                        opacity: 1;
                        transform: translateY(-50%) scaleX(1);
                    }

                }
            }
          }

          .video-controls-container::before {
            content: "";
            position: absolute;
            bottom: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, .75), transparent);
            width: 100%;
            aspect-ratio: 6 / 1;
            z-index: -1;
            pointer-events: none;
          }

            // .video-container:hover .video-controls-container,
            // .video-container:focus-within .video-controls-container,
            // .video-container.paused .video-controls-container {
            // opacity: 1;
            // }

        .buttons{
            width:85%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .resource-button {
              display: inline-block;
              background: #0041E6;
              color: #ffffff;
              padding: 10px 20px;
              text-decoration: none;
              border-radius: 5px;
              font-weight: bold;
              transition: background-color 0.3s ease;
              cursor: pointer;
              font-family: 'SF-Pro-Text-Semibold';
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -0.32px;
        
              &:hover {
                background-color: #0056b3;
              }
            }
        }
    }
}


@media (max-width: 660px) {
  .coursecontent{
    .container{
      .video-container{
        .timeline-container{

        }
        .video-controls-container{
          .controls{
            display: flex;
            align-items: center;
            
            margin-top: -20px;
            .left-controllers{
              padding: 5px 0 5px 5px;
              .duration-container{
                .current-time{
                  font-size: 12px;
                }
                .total-time{
                  font-size: 12px;
                }
              }

              .volume-container{
                .mute-btn{
                  height: 20px;
                  width: 20px;
               }
              }

            }
            .right-controllers{
              padding: 5px 5px 5px 0;

              .playback-rate-selector{
                font-size: 12px;
              }
            }
            button{
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }
}