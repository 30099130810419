.card{
    width:100%;
    max-width: 360px;
    height: 389px;
    flex-shrink: 0;
    border-radius: 24px;
    border: 1px solid #303030;
    background: linear-gradient(180deg, rgba(102, 102, 102, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), lightgray 50% / cover no-repeat;
    backdrop-filter: blur(12.5px);
    overflow: hidden; 
    position: relative;
    cursor: pointer;

    .courseImage{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .courseTitle{
        position: absolute;
        bottom: 24px; // Position the text at the bottom of the image
        left: 24px;
        width: 100%;
        color: #FFF;
        font-family: 'SF-Pro-Text-Semibold';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        letter-spacing: -0.48px;
    }

    &.skeleton{
        border-radius: 24px;
        background: linear-gradient(180deg, rgba(0, 32, 112, 0.20) 0%, rgba(8, 49, 151, 0.20) 100%), rgba(29, 29, 29, 0.17);
        backdrop-filter: blur(12.5px);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        overflow: hidden;
        position: relative;

        &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;  // Start the animation from the left
        width: 300%;  // 3 times the width to have a smooth transition
        height: 100%;
        background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 100%
        );
            animation: shimmer 1.5s infinite;
        }

        .skeleton-title{
            margin-bottom: 10px;
            width:85%;
            border-radius: 8px;
            background: linear-gradient(271deg, #002070 -3.23%, #5A88FE 107.9%);
            // background-clip: text;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            height: 40px;
        }
    }
}

// Define the shimmer effect using keyframes
@keyframes shimmer {
    0% {
      left: -150%;
    }
    100% {
      left: 100%;
    }
  }
  
.card:hover{
    transform: rotateY(2deg) rotateX(2deg) scale(1.025);
    border: 1px solid #0041E6;
    box-shadow: 0px 0px 34.9px 0px rgba(0, 65, 230, 0.48);
}

@media (max-width:1250px){
    .card{
        width: 330px;
        height:359px;
    }
}

@media (max-width:1150px){
    .card{
        width: 310px;
        height:339px;
    }
}

@media (max-width:1100px){
    .card{
        width: 280px;
        height:309px;
    }
}
@media (max-width:994px){
    .card{
        width: 250px;
        height:289px;
    }
}