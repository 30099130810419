.whyDSG{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;


    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width:100%;
        max-width: 1100px;
        height: 523px;
        flex-shrink: 0;
        border-radius: 24px;
        border: 1px solid #303030;
        background: rgba(29, 29, 29, 0.17);
        backdrop-filter: blur(12.5px);
        padding:48px;
        // margin: 0 40px;
        h2{ 
            width: 100%;
            font-family: 'SF-Pro-Text-Regular';
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.96px;
            background: linear-gradient(90deg, #FFF 0%, #646464 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .points{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .points-column{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start; // Align items to the start of the flex container
                flex-basis: 50%;
                gap:30px;
                .point-item {
                    margin:0 0 16px 0;
                    padding:15px;
                    gap:16px;
                    width: 500px;
                    height: 130px;
                    flex-shrink: 0;
                    border-radius: 16px;
                    border: 1px solid #303030;
                    background: rgba(29, 29, 29, 0.48);
                    backdrop-filter: blur(12.5px);
                    .point-title {
                        margin:0;
                        font-family: 'SF-Pro-Text-Semibold';
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: -0.48px;
                        background: linear-gradient(91deg, #6994FF -0.16%, #0036BE 103.45%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
          
                    .point-description {
                        width: 100%;
                        color: #C9C9C9;
                        font-family: 'SF-Pro-Text-Regular';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.32px;
                    }
                  }
          
            }
        }
    }
}

@media (max-width:1400px){
    .whyDSG{
        .container{
            max-width: 1000px;

            .points{
                .points-column{
                    .point-item{
                        width: 450px;
                    }
                }
            }
        }
    }
}

@media (max-width:1080px){
    .whyDSG{
        .container{
            max-width: 850px;
            .points{
                .points-column{
                    .point-item{
                        width: 380px;
                    }
                }
            }
        }
    }
}

@media (max-width:998px){
    .whyDSG{
        .container{
            max-width: 750px;
            height: auto;
            padding:48px 10px;
            h2{
                text-align: center;
            }
            .points{
                flex-direction: column;
                .points-column{
                    .point-item{
                        width: 380px;
                    }
                }
            }
        }
    }
}

@media (max-width:850px){
    .whyDSG{
        .container{
            max-width: 580px;
            height: auto;
            .points{
                flex-direction: column;
                .points-column{
                    .point-item{
                        width: 380px;
                    }
                }
            }
        }
    }
}

@media (max-width:700px){
    .whyDSG{
        .container{
            // max-width: 370px;
            height: auto;
            h2{
                font-size: 34px;
            }
            .points{
                width:100%;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap:24px;
                .points-column{
                    width:100%;
                    align-items: center;
                    justify-content: center;    

                    .point-item{
                        height: auto;
                        width: 340px;
                    }
                }
            }
        }
    }
}