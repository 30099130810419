.cta{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // margin: 120px auto;
    position: relative;

    .first-flare{
        position: absolute; /* Absolute positioning takes it out of the normal flow */
        top: 0; /* Align it with the top edge of the parent */
        z-index: 1; /* Lower z-index means it will be underneath the content-container */
        width: 82%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .flare-container{
            background-color: #6994FF;
            width: 170px;
            height: 170px;
            flex-shrink: 0;
            fill: #6994FF;
            filter: blur(500.75px);
        }
    }
    .second-flare{
        position: absolute; /* Absolute positioning takes it out of the normal flow */
        top: 400px; /* Align it with the top edge of the parent */
        z-index: 1; /* Lower z-index means it will be underneath the content-container */
        // margin-top: -100px;
        bottom: 0;
        width: 82%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .flare-container{
            background-color: #6994FF;
            width: 170px;
            height: 170px;
            flex-shrink: 0;
            fill: #6994FF;
            filter: blur(500.75px);
        }
    }
    .content-container{
        z-index: 2; /* Higher z-index to ensure it's above the flares */
        position: relative; /* Position relative to make z-index effective */
        margin: 0 auto; /* Center the container */
        // padding: 0 20px; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 1170px;
        min-height: 275px;
        flex-shrink: 0;
        border-radius: 24px;
        background: linear-gradient(180deg, rgba(0, 32, 112, 0.20) 0%, rgba(8, 49, 151, 0.20) 100%), rgba(29, 29, 29, 0.17);
        backdrop-filter: blur(12.5px);
        padding:50px 0;
        margin: 0 auto;
        .line1{
            color: #FFF;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 58px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -1.28px;
            margin: 8px 0;
            .line1-subtext{
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 58px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -1.28px;
                background: linear-gradient(271deg, #002070 -3.23%, #5A88FE 107.9%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .line2{
            margin-top: 8px ;
            margin-bottom: 64px;
            color: #FFF;
            text-align: center;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.96px;
            .line2-subtext{
                background: linear-gradient(92deg, #0041E6 -13.16%, #6994FF 114.54%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .join-us-link{
            display: flex;
            width: 200px;
            height: 30px;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 67px;
            background: #0041E6;
            text-decoration: none;
            .button{
                color: #FFF;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.32px;
                border:none;
                background-color: transparent;
                cursor: pointer;
            }
        }
    }
}

@media (max-width:1200px){
    .cta{
        .content-container{
            max-width: 1000px;
            // margin: 0 20px;
            .line1{
                font-size: 50px;
            }
            .line2{
                font-size: 44px;
            }
        }
    }
}
@media (max-width:1100px){
    .cta{
        .content-container{
            max-width: 800px;
            // margin: 0 20px;
            .line1{
                text-align: center;
                font-size: 46px;
            }
            .line2{
                text-align: center;
                font-size: 38px;
            }
        }
    }
}
@media (max-width:880px){
    .cta{
        .content-container{
            max-width: 750px;
            .line1{
                text-align: center;
                font-size: 40px;
                .line1-subtext{
                }
            }
            .line2{
                text-align: center;
                font-size: 40px;
            }
        }
    }
}
@media (max-width:668px){
    .cta{
        .content-container{
            max-width: 450px;
            .line1{
                text-align: center;
                font-size: 38px;
                .line1-subtext{
                    font-size: 38px;
                }
            }
            .line2{
                text-align: center;
                font-size: 32px;
            }
        }
    }
}