.whatyoulllearn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .container {
        width: 100%;
        max-width: 1150px;
        min-height: 403px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding:48px;
        border-radius: 24px;
        border: 1px solid #303030;
        background: rgba(29, 29, 29, 0.17);
        backdrop-filter: blur(12.5px);

        h2 {     
            width: 100%;
            text-align: left;
            font-family: 'SF-Pro-Text-Regular';
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.96px; 
            background: linear-gradient(90deg, #FFF 0%, #646464 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
         }
  
      .points-container {
        display: flex;
        justify-content: space-between; // Or use 'justify-content: space-around' for even spacing
        gap:135px;
  
        .points {
          list-style: none;
          padding: 0;
          margin: 0;
          flex: 1; // Both lists share the available space equally
          gap:24px;

          .point {
            display: flex;
            align-items: center;
            gap:16px;
            padding:12px 0;
  
            img {
                    width: 20px;
                    height: 20px;
                    flex-shrink: 0;
            }
  
            span {
                width: 470px;
                color: #C9C9C9;
                font-family: 'SF-Pro-Text-Regular';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.32px;
            }
          }
        }
      }
    }
  }
  
  @media (max-width:1400px){
    .whatyoulllearn{
      .container{
        // justify-content: center;
        // gap:60px;
        max-width: 1000px;
       
        .points-container{
          justify-content: center;
          gap:20px;
        
        }
      }
    }
  }
  @media (max-width:1080px){
    .whatyoulllearn{
      .container{
        max-width: 850px;
        h2{
          text-align: center;
        }
        .points-container{
          flex-direction: column;
          gap:10px;
        }
      }
    }
  }
@media (max-width:998px){
    .whatyoulllearn{
      .container{
        padding: 25px;
        max-width: 750px;
      }
    }
  }  

  @media (max-width:850px){
    .whatyoulllearn{
      .container{
        // padding:25px;
        max-width: 580px;
      }
    }
  }  

  @media (max-width:700px){
    .whatyoulllearn{
      .container{
        padding:42px;
        max-width: 300px;

        h2{
          font-size: 34px;
        }
        .points-container{
          width: 100%;
          .points{
            width: 100%;
          }
        }
      }
    }
  }  