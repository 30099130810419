.heroSection{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 100px;

    .container{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                max-width:1074px;
                min-height:388px;
                border-radius: 24px;
                border: 1px solid #303030;
                background: rgba(29, 29, 29, 0.17);
                backdrop-filter: blur(12.5px);
                padding:10px;
                padding:56px 48px 0 48px;

        .main-hero-section-maintitle{
            margin: 0;
            // margin-top: 35px;
            // margin-bottom: -50px;
            padding: 0 48px;                  
            width:100%;
            max-width: 1074px;
            color: #FFF;
            // font-family: "SF Pro Display";
            font-size: 62px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -1.28px;
            .data-mastery{
                color: #0041E6;
            }
        }
        .hero-section-bottom-part{
                    
            width:100%;
            max-width: 1074px;
            display: flex;
            margin-top: -35px;
            align-items: center;
            justify-content: space-between;
            .left-half{
                display: flex;
                align-items: start;
                justify-content: start;
                flex-direction: column;
                .subtitle{
                    color: #747474;
                    // font-family: "SF Pro Display";
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.64px;
                    margin-bottom: 60px;
                    margin-top: -50px;
                }
                .find-courses-button{
                    margin-bottom: 60px;
                    display: inline-flex;
                    padding: 12px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 67px;
                    background: linear-gradient(180deg, #0041E6 0%, #0D44CF 53.5%);
                    color: #FFF;
                    // font-family: "SF Pro Display";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.4px;
                    cursor: pointer;

                    .link{
                        text-decoration: none;
                        color: #fff;
                    }

                }
                .hero-section-points{
                    display: flex;
                    align-items: flex-start;
                    justify-content: start;
                    flex-direction: column;
                    gap:20px;
                    .points-line{
                        display: flex;
                        align-items: flex-start;
                        justify-content: start;
                        gap:12px;
                        .point1,.point2,.point3{
                            display: inline-flex;
                            padding: 8px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            color: #FFF;
                            // font-family: "SF Pro Display";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.32px;
                            border-radius: 8px;
                            background: #1E1E1E;
                        }
                    }
                }
            }
            .right-half{
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 460px;
                height: 460px;
                flex-shrink: 0;
                .hero-image{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
           
        }
    }
}

@media (max-width:1200px){
    .heroSection{
        .container{
            margin: 0 20px;
            .main-hero-section-maintitle{
                font-size: 56px;
            }
            .hero-section-bottom-part{
                .left-half{
                    .subtitle{
                        font-size: 24px;
                        margin-bottom: 30px;
                    }
                    .find-courses-button{

                    }
                    .hero-section-points{
                        .points-line{
                            .point1,.point2,.point3{

                            }
                        }
                    }
                }
                .right-half{
                    min-width: 420px;
                    height: 420px;
                    .hero-image{

                    }
                }
            }
        }
    }

}

@media (max-width:998px){
    .heroSection{
        .container{
            padding:56px 48px 56px 48px;

            .main-hero-section-maintitle{
               font-size: 48px;
            }
            .hero-section-bottom-part{
                margin-top: 10px;
                // margin: 0;


                .left-half{
                    .subtitle{
                        margin-top: 0;
                    }
                    .find-courses-button{

                    }
                    .hero-section-points{
                        .points-line{
                            .point1,.point2,.point3{

                            }
                        }
                    }
                }
                .right-half{
                    display: none;
                }
            }
        }
    }

}
@media (max-width:660px){
    .heroSection{
        .container{
            .main-hero-section-maintitle{
                font-size: 38px;
            }
            .hero-section-bottom-part{
                .left-half{
                    .subtitle{
                        font-size: 21px;
                    }
                    .find-courses-button{

                    }
                    .hero-section-points{
                        .points-line{
                            .point1,.point2,.point3{

                            }
                        }
                    }
                }
                .right-half{
                    display: none;
                }
            }
        }
    }

}