.coursePage{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .container{
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        width: 100%;
        padding:50px 32px;
        gap:50px;

        h2{
            margin: 0;
            width: 100%;
            color: #FFF;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.96px;
            text-align: left;
        }
          .courseCards{
            width:100%;
            display: flex;
            flex-wrap: wrap; // Allow the items to wrap to the next line
            justify-content: start; // Distribute space between and around items
            gap: 15px;
            // .card {
            //     width: calc(33.333% - 20px); // Adjust the width so 3 cards fit in the row with gaps
            //     margin-bottom: 30px; // Optional: add space between rows
            // }
        }
    }
}

@media (max-width:998px){
    .coursePage{
        .container{
            .courseCards{
                justify-content: center;
            }
        }
    }
}