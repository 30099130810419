.sidebar-content{
    display: flex;
    align-items: start;
    justify-content: start;
    width:100%;
    max-width: 340px;
    height: 100vh;
    flex-shrink: 0;
    // border: 1px solid #303030;
    position: sticky;
    top:0;
    background: #000000;
    // box-shadow: 0px 4px 33.5px 0px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease-out; // Smooth transition for sliding
    will-change: transform; // Optimizes animations
    // position: fixed; // Fixed position to stay in view
    left: 0; // Align to the left side
    top: 0; // Align to the top

    /* Custom scrollbar styles */
    &::-webkit-scrollbar {
        width: 5px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Color of the track */
    }

    &::-webkit-scrollbar-thumb {
        background: #888; /* Color of the scrollbar itself */
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color when hovered */
    }

    &.hidden {
        transform: translateX(-100%); // Hide the sidebar off-screen
    }

    .sidebar-container{
        // padding:20px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        width:100%;
        max-width: 260px;
        min-height: 88vh;
        
        


        .top-menu{
            display: flex;
            align-items: start;
            justify-content: start;
            flex-direction: column;
            gap:20px;

            .logo{
                display: none;
            }

            .sidebar-menu{
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                gap:20px;
                width:100%;
    
                h2{
                    color: #FFF;
                    font-family: 'SF-Pro-Text-Semibold';
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.48px;
                    width: 100%;
                    text-align: left;
                }
                .menu-item{
                    display: flex;
                    width:100%;
                    max-width: 260px;
                    padding: 12px 16px;
                    align-items: start;
                    gap: 10px;
                    img{
                        padding:0 5px;
                    }
                    gap:5px;
                    color: #FFF;
                    font-family: 'SF-Pro-Text-Medium';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.32px;
                    text-decoration: none;
                    display: flex;
                    align-items: start;
                    justify-content: start;
                    width: 200px;
                    padding: 10px 16px;                
                }
                .menu-item:hover{
                    width: 200px;
                    padding: 10px 16px;
                    border-radius: 8px;
                    background: #1D1D1D;
                }
            }
            
            .sidebar-user-menu{
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                gap:20px;
                width:100%;
    
                .menu-item{
                    img{
                        padding:0 5px;
                    }
                    gap:5px;
                    color: #FFF;
                    font-family: 'SF-Pro-Text-Medium';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.32px;
                    text-decoration: none;
                    display: flex;
                    align-items: start;
                    justify-content: start;
                    // padding: 0 5px;
                    width: 200px;
                    padding: 10px 16px;
                }
                .menu-item:hover{
                    // display: flex;
                    width: 200px;
                    padding: 10px 16px;
                    // align-items: center;
                    // gap: 10px;
                    border-radius: 8px;
                    background: #1D1D1D;
                }
             
            }
        }
        .user-account{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap:10px;
            .account{
                    width: 50px;
                    height: 50px;
                    color:#0041E6;
                    cursor: pointer;
            }
            .username,.useremail{
                color: #FFF;
                font-family: 'SF-Pro-Text-Regular';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.4px;
            }
        }
    }
}



@media (max-width:1080px){
    .sidebar-content{
        min-height: 100vh; /* Full viewport height */
        overflow-y: auto; /* Scroll inside sidebar if content overflows */
        .sidebar-container{
            // padding:auto 0;
            min-height:100vh ;
           
            .top-menu{
                .logo{
                    margin-top: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    cursor: pointer;
                    width: 100%;
                    margin-bottom: 10px;
                    .menu-icon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color:white;
                        margin-top: -15px;
                    }
                    .logoimage{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        object-fit: cover;
                        width: 120px;
                        height: 40px;
                        flex-shrink: 0;
                        margin-top: 4px;
                    }
                }

                .sidebar-menu{
                    h2{
                        margin: 0;
                    }
                }

                .sidebar-user-menu{
                    .user-account{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}