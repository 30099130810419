.features{
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 120px 0;
    
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap:30px;
        .features-line{
            display: flex;
            align-items: center;
            justify-content: center;
            gap:30px;

            .point1,.point2{
                width:100%;
                max-width: 440px;
                min-height: 120px;
                flex-shrink: 0;
                border-radius: 24px;
                border: 1px solid #303030;
                background: rgba(29, 29, 29, 0.48);
                backdrop-filter: blur(12.5px);
                display: flex;
                align-items: center;
                justify-content: center;
                gap:24px;
                .point-image{
                    width: 65px;
                    height: 65px;
                    flex-shrink: 0;
                }
                .point-text{
                    // font-family: "SF Pro Display";
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.8px;
                    background: linear-gradient(90deg, #FFF 0%, #646464 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                }
            }
        }
    }
}

@media (max-width:998px){
    .features{
        .container{
            margin: 0 20px;
            .features-line{
                // flex-direction: column;
                .point1,.point2{
                    max-width: 340px;
                    .point-image{
                        width: 50px;
                        height: 50px;
                        flex-shrink: 0;
                    }
                    .point-text{
                        font-size: 28px;
                    }
                }
            }
        }
    }
}
@media (max-width:660px){
    .features{
        .container{
            margin: 0 20px;
            .features-line{
                width: 100%;
                flex-direction: column;
                .point1,.point2{
                    max-width: 400px;
                    min-width:320px;
                    .point-image{
                        width: 50px;
                        height: 50px;
                        flex-shrink: 0;
                    }
                    .point-text{
                        font-size: 28px;
                    }
                }
            }
        }
    }
}