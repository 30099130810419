.testimonial-card {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    max-width: 370px;
    min-height: 419px;
    flex-shrink: 0;
    border-radius: 24px;
    border: 1px solid #303030;
    background: rgba(29, 29, 29, 0.48);
    backdrop-filter: blur(12.5px);  
    perspective: 1000px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    .testimonial-content-part {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform-style: preserve-3d;

  
      .testimonial-content {
        width: 100%;
        // max-width: 322px;

        .testimonial-review {
            margin:0;
            color: #C9C9C9;
            font-family: 'SF-Pro-Text-Regular';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.36px; 
            padding: 24px 24px 10px 24px;
 
            }
      }
  
      .testimonial-rating {
        width: 88%;
        align-items: start;
        font-size: 1.5em;
        // width: 24px;
        // height: 24px;
        // flex-shrink: 0;
        color: #ffd700; //
        // margin: 0 24px;
          }
    }
  
    .testimonial-user-details {
        display: flex;
        align-items: center;
        justify-content: start;
        padding:0 24px 24px 24px;
        gap:16px;
        width:87%;
        transform-style: preserve-3d;
  
      .user-image {
        width: 64px;
        height: 64px;
        flex-shrink: 0;
        border-radius: 64px;
        .testimonial-user-image {
            width: 100%;
            height:100%;
            object-fit: cover;
        }
      }
  
      .user-details {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap:12px;
        
  
        .user-name {
            color: #C9C9C9;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.36px;
        }
  
        .user-role {
            display: inline-flex;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #1E1E1E;
            font-family: 'SF-Pro-Text-Semibold';
        }
      }
    }
  }
  .testimonial-card:hover{
    transform: rotateY(2deg) rotateX(2deg) scale(1.025);
    border: 1px solid #0041E6;
    box-shadow: 0px 0px 34.9px 0px rgba(0, 65, 230, 0.48);
  }

