.who-we-are{
    // margin-top: -80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // margin: 40px 0;
    .who-we-are-container{
        width:100%;
        display: flex;
        align-items: center; 
        justify-content: center;
        max-width:1106px;
        min-height:355px;
        border-radius: 24px;
        border: 1px solid #303030;
        background: rgba(29, 29, 29, 0.17);
        backdrop-filter: blur(12.5px);
        gap: 73px;
        padding:32px;
        .who-we-are-text{
            display: flex;
            flex-direction: column;
            align-items: flex-start; // Align text at the start
            gap: 16px; 
            width: 100%;
            max-width: 622px;
        .who-we-are-maintitle{
                // font-family: "SF Pro Display";
                font-size: 48px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.96px;
                background: linear-gradient(90deg, #FFF 0%, #646464 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .who-we-are-subtitle{
                color: #C9C9C9;
                // font-family: "SF Pro Display";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.4px;
                margin-bottom: 24px;
            }
            .who-we-are-points{
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: start;
                gap:20px;
                .points{
                    display: flex;
                    align-items: flex-start;
                    justify-content: start;
                    gap:12px;
                    .point-one,.point-two{
                        display: inline-flex;
                        padding: 8px 16px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 8px;
                        background: linear-gradient(180deg, #0041E6 0%, #0036BE 100%);
                        color: #FFF;
                        // font-family: "SF Pro Display";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.32px;
                    }
                }
            }
        }
        .who-we-are-image-container {
            flex-shrink: 0;
            border-radius: 16px;
            overflow: hidden; // Ensure image does not overflow container
            position: relative; // Add position relative
        
            .who-are-we-image {
                width: 350px; // Adjust width as needed
                height: 300px; // Adjust height as needed
            }
          }
    }
}

@media (max-width:1200px){
    .who-we-are{
        .who-we-are-container{
            margin: 0 20px;
        }
    }
}
@media (max-width:998px){
    .who-we-are{
        .who-we-are-container{
            flex-direction: column-reverse;
        }
    }
}
@media (max-width:660px){
    .who-we-are{
        .who-we-are-container{
            .who-we-are-text{
                .who-we-are-maintitle{
                    font-size: 38px;
                }
            }
            .who-we-are-image-container{
                // min-width: 300px; /* Set minimum width */
                // min-height: 300px;
                width: 100%;
                max-width: 335px;
                min-height: 280px;
                display: flex;
                align-items: center;
                justify-content: center;
                .who-are-we-image{
                    width: 100%; /* Make the image responsive */
                    height: auto; 
                }
            }
        }
    }
}