.courseherosection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // margin-top: 100px;
  
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1150px;
        height: 580px;
        flex-shrink: 0;
        border-radius: 24px;
        background: linear-gradient(180deg, #093BB8 0%, #002070 100%);
        backdrop-filter: blur(12.5px);
        padding:40px;
  
      .herosection-leftside {
        width:50%;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        gap: 90px;
        
        
        .course-content {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            gap:10px;

          .maintitle {
            margin: 0;
            width:50%;
            color: #FFF;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 64px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -1.28px;
          }
  
          p {
            width: 100%;
            max-width: 500px;
            color: #FFF;
            font-family: 'SF-Pro-Text-Regular';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.4px;
          }
  
          .enroll-now-button {
            display: flex;
            width: 210px;
            height: 25px;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;  
            border-radius: 67px;
            border: 1px solid #FFF;
            cursor: pointer;
            text-decoration: none;
            .link{
                color: #FFF;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.32px;
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
          }
        }
  
        .hero-section-bottom-part {
            display: flex;
            align-items: start;
            justify-content: start;
            flex-direction: column;
            gap:20px;
              
            .points-line {
                display: flex;
                align-items: flex-start;
                justify-content: start;
                gap:12px;

              .point1,
              .point2,
              .point3 {
                            display: inline-flex;
                            padding: 8px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            color: #FFF;
                            // font-family: "SF Pro Display";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.32px;
                            border-radius: 8px;
                            background: #083197;
              }
            }
        }
      }

      .herosection-rightside {
        width: 530px;
        height: 500px;
        flex-shrink: 0;
        border-radius: 16px;
        border: 1px solid #303030;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 65, 230, 0.10) 100%), lightgray 50% / cover no-repeat;
        backdrop-filter: blur(12.5px);
      
        .course-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 16px;

        }
      }
      
    }
  }

  @media (max-width:1400px){
    .courseherosection{
      .container{
        justify-content: center;
        gap:60px;
        max-width: 1000px;
        .herosection-leftside{
          width:40%;
        }
        .herosection-rightside{
          width: 440px;
          height: 410px;
        }
      }
    }
  }

  @media (max-width:1080px){
    .courseherosection{
      .container{
        max-width: 850px;
        gap:60px;

        .herosection-leftside{
          width:50%;
        }
        .herosection-rightside{
          width: 400px;
          height: 370px;
        }
      }
    }
  }

  @media (max-width:998px){
    .courseherosection{
      .container{
        max-width: 750px;
        flex-direction: column-reverse;
        height:auto;
        .herosection-leftside{
          width:100%;
          height:80%;
          gap: 40px;
          .course-content{
            .maintitle{
              width:100%;
            }
            p{
              max-width: 100%;
            }
          }
        }
        .herosection-rightside{
          width:100%;
          // height: auto;
          object-fit: contain;
          .course-image{
            width: 100%;
            // height: 50%;
            object-fit: cover;
            border-radius: 16px;
          }
        }
      }
    }
  }

  @media (max-width:850px){
    .courseherosection{
      .container{
        max-width: 580px;
      }
    }
  }

  @media (max-width:700px){
    .courseherosection{
      .container{
        padding:20px;
        max-width: 350px;

        .herosection-leftside{
          .course-content{
            .maintitle{
              font-size: 52px;
            }
            .enroll-now-button{
              width: 80%;
            }
          }
        }
      }
    }
  }