.dashboard-navbar{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    z-index: 9999;
    // background: #0E0E0E;
    // box-shadow: 0px 4px 33.5px 0px rgba(0, 0, 0, 0.25);
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width:100%;
        padding:10px 20px;
        .logo{
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .menu-icon{
                display: flex;
                align-items: center;
                justify-content: center;
                color:white;
                margin-top: -15px;
            }
            .logoimage{
                display: flex;
                align-items: center;
                justify-content: center;
                object-fit: cover;
                width: 120px;
                height: 40px;
                flex-shrink: 0;
                margin-top: 4px;
            }
        }
        .right-navbar{
            display: flex;
            align-items: center;
            justify-content: center;
            gap:10px;
            // width:50%;
            .navlinks{
                display: flex;
                align-items: center;
                justify-content: center;
                ul{ 
                    margin: 10px 0 5px 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    list-style: none;
                    li{
                        color:white;
                        .link{
                            display: inline-flex;
                            padding: 8px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            color: #FFF;
                            font-family: 'SF-Pro-Text-Regular';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.32px;
                            transition: background 0.5s ease;
                            &:hover {
                                text-decoration: underline;
                                text-decoration-color: #0041E6; // Underline color on hover
                                color:#0041E6;
                            }
                        }
                    }
                }
            }
            .account-image{
                display: flex;
                align-items: center;
                justify-content: center;
    
                .account{
                    width: 40px;
                    height: 40px;
                    color:#0041E6;
                    cursor: pointer;
                }
                .dropdown-menu{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    position: absolute;
                    top:70px;
                    right:20px;
                    border-radius: 18px;
                    border: 1px solid #303030;
                    background: rgba(29, 29, 29, 0.17);
                    backdrop-filter: blur(12.5px);
                    z-index: 99999;
                    gap:10px;
                    color:white;
                    width:150px;
                    height:120px;
                    padding:10px;
                    .link{
                        color:white;
                        text-decoration: none;
                        font-size: 17px;
                        transition: color 0.3s ease, text-decoration-color 0.3s ease; 
    
                        &:hover {
                           
                            text-decoration: underline;
                            text-decoration-color: #0041E6; // Underline color on hover
                            color:#0041E6;
                        }
                    }
                }
            }
        }
        
    }
}

@media (max-width:998px){
    .dashboard-navbar{
        .container{
            .logo{
                margin-top: 20px;
            }
            .account-image
            {
                .dropdown-menu{
                    min-height:200px;
                    width:100%;
                    right:0;
                    // overflow: hidden;
                    padding:8px 0;
                    z-index: 101;
                    // gap:15px;
                    .link{
                        padding:5px 0;
                        text-align: center;
                        width: 100%;
                        &:hover::after {
                            content: "";
                            position: absolute;
                            bottom: 0; // Position at the bottom of the link
                            left: 0;
                            width: 100%;
                            height: 2px;
                          //   background-color: #0041E6;
                          }
                    }
                }
            }
        }
    }
}

@media (max-width:660px){
    .dashboard-navbar{
        .container{
            .right-navbar{
                display: none;
            }
        }
    }
}