.chat_with_us{
    display: flex;
    align-items: center;
    justify-content: start;
    position: fixed;
    bottom: 25px;
    right: 25px;
    padding: 10px 20px;
    background-color: #006DFE; // WhatsApp brand color
    color: white;
    border: none;
    border-radius: 50px; // This will make it oval-shaped
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    gap:10px;
    font-size:18px;
  
    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); // Darker box-shadow on hover
    }

        .whatsapp_image{
            width:40px;
            height:40px;
            color:white;
        }
        .chat_with_us_text{
            font-family: 'SF-Pro-Text-Light';
            font-weight: 300;
        }
}
@media (max-width:660px){
    .chat_with_us{
        .whatsapp_image{
            border-radius: 50%;
        }
        .chat_with_us_text{
            display: none;
        }
    }
}