.purchasedCourseCard {
    width: 100%;
    max-width: 360px; // Your design's width for the card
    display: flex;
    flex-direction: column;
    background: #000; // Based on your design background
    border-radius: 24px; // Your design's border radius
    overflow: hidden; // Ensures the image adheres to the border-radius
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);

    &.loading{
        border-radius: 24px;
        background: linear-gradient(180deg, rgba(0, 32, 112, 0.20) 0%, rgba(8, 49, 151, 0.20) 100%), rgba(29, 29, 29, 0.17);
        backdrop-filter: blur(12.5px);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -150%;  // Start the animation from the left
            width: 300%;  // 3 times the width to have a smooth transition
            height: 100%;
            background-image: linear-gradient(
                to right,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.8) 50%,
                rgba(255, 255, 255, 0) 100%
            );
                animation: shimmer 1.5s infinite;
        }
        .course-details{
            background: linear-gradient(271deg, #002070 -3.23%, #5A88FE 107.9%);
        }
    }


    .image-container{
        width: 100%; /* Full width of the parent */
        height: 240px; /* Height that you want your image container to have */
        display: flex; /* This will allow you to center the image if needed */
        align-items: center; /* Center image vertically */
        justify-content: center;
        .course-image {
            width: 100%; /* Full width of the parent */
            height: 240px; /* Fixed height as per your requirement */
            object-fit: cover; // Cover the specified area
      }
    }
  
  .course-details {
    width: 100%;
    max-width: 322px;
    min-height: 168px;
    border-radius: 0 0 24px 24px;
    border: 1px solid #303030;
    background: linear-gradient(180deg, rgba(102, 102, 102, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
    backdrop-filter: blur(12.5px);
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    padding:24px;

    
    .course-title {
        color: #FFF;
        font-family: 'SF-Pro-Text-Semibold';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.48px;
        margin-bottom: 40px;
    }
    
    .course-button {
        border: none;
        display: flex;
        width: 300px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 67px;
        background: #0041E6;
        margin-bottom: 12px;
        cursor: pointer;
        .link{
            color: #FFF;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.32px;
        }
    }
    
    .invoice-button {
        display: flex;
        width: 300px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 67px;
        border: 1px solid #FFF;
        background-color: transparent;
        .link{
            color: #FFF;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.32px;
        }
    }
  }
}
// Define the shimmer effect using keyframes
@keyframes shimmer {
    0% {
      left: -150%;
    }
    100% {
      left: 100%;
    }
  }
  
// .card:hover{
//     transform: rotateY(2deg) rotateX(2deg) scale(1.025);
//     border: 1px solid #0041E6;
//     box-shadow: 0px 0px 34.9px 0px rgba(0, 65, 230, 0.48);
// }

@media (max-width:1250px){
    .purchasedCourseCard{
        width: 340px;
        .image-container{
            height: 220px;
        }
        .course-details{
            min-height: 168px;
        }
    }
}
@media (max-width:998px){
    .card{
        width: 280px;
        height:309px;
    }
}
