.cancelled-page{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 80px;
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width:1170px;
        gap:30px;
        height: 85vh;

        h2{
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 64px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -1.28px;
            background: linear-gradient(271deg, #002070 -3.23%, #5A88FE 107.9%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 0;
        }
        span{
            color: #FFF;
            text-align: center;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.96px;
        }
        button{
            display: flex;
            width: 418px;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 67px;
            border: none;
            background: #0041E6;
            color: #FFF;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.32px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            .link{
                text-decoration: none;
                color: white;
                transition: background-color 0.3s ease;
            }
        }
    }
}
@media (max-width:1080px){
    .cancelled-page{
        .container{
            h2{
                font-size: 52px;
            }
            span{
                font-size: 28px;
            }
            button{
            width: 360px;
            }
        }
    }
}

@media (max-width:998px){
    .cancelled-page{
        .container{
            h2{
                font-size: 42px;
            }
            span{
                font-size: 26px;
            }
            button{
                width: 360px;
            }
        }
    }
}

@media (max-width:660px){
    .cancelled-page{
        height: 60vh;
        .container{
            h2{
                font-size: 32px;
            }
            span{
                padding:0 10px;
                font-size: 21px;
            }
            button{
                width: 320px;
            }
        }
    }
}