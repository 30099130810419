.courselistingpage{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    margin-top:80px;
    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width:100%;
        max-width: 1170px;
        flex-shrink: 0;
        margin-bottom: 300px;
        .search-container{
            margin: 40px 20px;
            width:100%;
            max-width: 1074px;
            min-height: 178px;
            flex-shrink: 0;
            border-radius: 24px;
            background: linear-gradient(180deg, #093BB8 0%, #002070 100%);
            backdrop-filter: blur(12.5px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:56px 48px;
            // margin: 0 20px;
            .search-container-left-side{
                width:100%;
                max-width: 511px;
                gap:16px;
                display: flex;
                align-items: start;
                justify-content: start;
                flex-direction: column;

                h2{
                    color: #FFF;
                    font-family: 'SF-Pro-Text-Semibold';
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.96px;
                    margin: 0;

                }
                p{
                    color: #FFF;
                    font-family: 'SF-Pro-Text-Regular';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.4px;
                }
            }
            .search-container-right-side{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                min-height:100%;
    
               input{
                display: flex;
                width: 380px;
                padding: 20px 24px;
                align-items: center;
                gap: 10px;
                border-radius: 76px;
                border: 1px solid #FFF;
                background-color: transparent;
                color: #FFF;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.32px;
                outline: none;
               }
    
               input::placeholder {
                color: #FFF;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.32px;
               }
    
            }
        }
       
        .courseCards{
            width:100%;
            display: flex;
            flex-wrap: wrap; // Allow the items to wrap to the next line
            justify-content: start; // Distribute space between and around items
            gap: 20px;
            // .card {
            //     width: calc(33.333% - 20px); // Adjust the width so 3 cards fit in the row with gaps
            //     margin-bottom: 30px; // Optional: add space between rows
            //   }
        }
    }
}

@media (max-width:1250px){
    .courselistingpage{
        .container{
            .search-container{
                width: 88%;
                padding: 56px 24px; 
            .courseCards{
                width: 90%;
            }
        }
    }
}
@media (max-width:998px){
    .courselistingpage{
        .container{
            .search-container{
                width: 85%;
                padding: 40px 24px; 
            }
        }
    }
}



@media (max-width:900px){
    .courselistingpage{
        .container{
            .search-container{
                width:90%;
                flex-direction: column;
                gap:25px;
                padding: 40px 12px;
                .search-container-left-side{
                    gap:6px;
                    align-items: center;
                    justify-content: center;
                }
                .search-container-right-side{
                    max-width: 100%; // Allow these elements to take full width
                    justify-content: center;
                    align-items: center;
                    input{
                        width: 100%; // Make input take full width to utilize space
                        max-width: 360px;
                    }
                }
            }
            .courseCards{
                justify-content: center;
            }
        }
    }
}}