.resetpassword {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    .container {
        display: flex;
        align-items: center;
        justify-content: start;
        width:100%;
        max-width: 900px;
        min-height: 500px;
        flex-shrink: 0;
        border-radius: 24px;
        border: 1px solid #303030;
        background: rgba(29, 29, 29, 0.17);
        backdrop-filter: blur(12.5px);      
  
    .resetpassword-left-side {
        min-height: 500px;
        flex: 1; // This will make the left side fill 50% of the .container
        display: flex;
        align-items: start;
        justify-content: start;
        border-radius: 24px 0px 0px 24px;
       
        .image{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }
  
    .resetpassword-form {
        flex:1;  
        height:500px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap:20px;
      .resetpassword-form-top-section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap:20px;
        flex-direction: column;

        .forgotpassword-form-header {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            gap:13px;
            padding: 0 45px; 
            width: 80%;
            margin-top:-30px;
            
            h2{ 
                margin-top:15px;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.96px;
                margin-bottom: 2px;
                text-align: left;
                color:#fff;
            }  
         
        }

        .form {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .button{
                border-radius: 67px;
                background: #0041E6;
                display: flex;
                width: 360px;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #FFF;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.32px;
            }

            .MuiTextField-root {
                margin-bottom: 5px;
    
                .MuiInputLabel-filled {
                    // Targets the label of the filled TextField
                    color: #0041E6; // Default label color
                }
    
                .MuiFilledInput-underline:after {
                    // Targets the underline of the TextField after being filled (focused)
                    border-bottom-color: #0041E6 !important; // Changes the underline color to blue
                }
    
                .MuiFilledInput-input {
                    // Targets the input text itself
                    color: white; // Changes the text color to white
                }
    
                .MuiFilledInput-root {
                    // Targets the root of the filled input
                    background-color: rgba(255, 255, 255, 0.09); // Changes the background color
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.13); // Changes background color on hover
                    }
                    &.Mui-focused {
                        background-color: rgba(255, 255, 255, 0.12); // Changes background color when focused
    
                        .MuiInputLabel-filled {
                            color: #0041E6 !important; // Changes the label color to blue when focused
                        }
                    }
                }
            }
  
            // Styles for TextField and button components can be added here
            .resetbutton{
                border:none;
                border-radius: 67px;
                background: #0041E6;
                display: flex;
                width: 360px;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #FFF;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.32px;
                cursor: pointer;
            }
        }
  
      }
      .resend-otp:hover{
        background-color: #11235A;
        border-radius: 8px;
      }
    }
  }
}

@media (max-width:1080px){
    .resetpassword{
        .container{
            max-width : 800px;
        }
    }
}

@media (max-width:900px){
    .resetpassword{
        .container{
            max-width : 600px;
            margin: 0 20px;
            justify-content: center;
            .resetpassword-left-side{
                display: none;
            }
            .resetpassword-form{
                margin-top: 15px;
                min-height: 380px;
                .resetpassword-form-top-section{
                    .forgotpassword-form-header{
                        width: 90%;
                        padding:0;
                    }
                    .form{
                        width: 90%;
                    }
                }
                .login-subtitle{
                    width:100%;
                    max-width:380px;
                }
            }
        }
    }
}

@media (max-width:660px){
    .resetpassword{
        height: 100vh;
        .container{
            max-width : 550px;
            margin: 10px 20px;
            justify-content: center;
            .resetpassword-left-side{
                display: none;
            }
            .resetpassword-form{
                margin-top: 15px;
                min-height: 380px;
                .resetpassword-form-top-section{
                    .forgotpassword-form-header{
                        width: 90%;
                        padding:0;
                    }
                    .form{
                        width: 90%;

                        .resetbutton{
                            width: 300px;
                        }
                    }
                }
                .login-subtitle{
                    width:100%;
                    max-width:380px;
                }
            }
        }
    }
}