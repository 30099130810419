.register {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    .container {
      display: flex;
      align-items: center;
      justify-content: start;
      width:100%;
      max-width: 900px;
      min-height: 500px;
      flex-shrink: 0;
      border-radius: 24px;
      border: 1px solid #303030;
      background: rgba(29, 29, 29, 0.17);
      backdrop-filter: blur(12.5px);
      margin:auto;
    
  
      .register-left-side{
        min-height: 500px;
        flex: 1; // This will make the left side fill 50% of the .container
        display: flex;
        align-items: start;
        justify-content: start;
        border-radius: 24px 0px 0px 24px;
        
        .image{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
  
    .register-form {
        flex: 1; // This will make the left side fill 50% of the .container
        height:450px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap:20px;
  
      .register-top-section {
            display: flex;
            align-items: center;
            justify-content: center;
            gap:40px;
            flex-direction: column;  
        .register-form-header {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                gap:13px;  
                    h2{
                        font-family: 'SF-Pro-Text-Semibold';
                        color: #FFF;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: -0.96px;                        
                        margin-bottom: 8px;
                    }
                    span{
                        color: #757575;
                        font-family: 'SF-Pro-Text-Regular';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        .link{
                            color: #757575;
                            font-family: 'SF-Pro-Text-Regular';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-decoration-line: underline;
                        }
                    }
        }
  
        .register-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap:10px;  
            .email-password{
                display: flex;
                align-items: center;
                justify-content: center;
                width:340px;
                background: #0041E6;                        color: #000;
                color: #000;
                height:50px;
                border-radius: 67px;
                text-decoration: none;
                .link{
                    color: #FFF;
                    font-size: 18px;
                    text-decoration: none;
                    font-family: 'SF-Pro-Text-Semibold';
                    background-color:transparent;
                    border: none;
                    cursor: pointer;
                }

            }
            }
      }
  
      .login-subtitle {
            color: #C9C9C9;
                font-family: 'SF-Pro-Text-Regular';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.36px;
                padding: 0 42px;    
        }
    }
  }
}

@media (max-width:1080px){
    .register{
        .container{
            max-width : 800px;
        }
    }
}

@media (max-width:998px){
    .register{
        .container{
            max-width : 600px;
            margin: 0 20px;
            justify-content: center;
            .register-left-side{
                display: none;
            }
            .register-form{
                min-height: 380px;
                .login-subtitle{
                    width:100%;
                    max-width:380px;
                    padding:10px;
                }
            }
        }
    }
}

@media (max-width:660px){
    .register{
        height: 100vh;
        .container{
            max-width : 600px;
            max-width : 500px;
            margin: 10px 0;
            justify-content: center;
            .register-left-side{
                display: none;
            }
            .register-form{
                min-height: 380px;
                .register-top-section{
                    .register-form-header{
                        h2{
                            font-size: 28px;
                        }
                    }
                    .register-buttons{
                        .email-password{
                            width: 300px;
                        }
                    }
                }
                .login-subtitle{
                    width:85%;
                    max-width:380px;
                    padding:10px;
                }
            }
        }
    }
}