.customer-support {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
    width:100%;
  
    .container {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      width:100%;
      max-width:1170px;
      margin:0 50px;


      .content {
        margin-top: 20px;
       .title {
          font-size: 42px;
          font-family: 'SF-Pro-Text-Semibold';
          background: linear-gradient(271deg, #002070 -3.23%, #5A88FE 107.9%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 600;
        }

        .contact-us{
          color:white;
        }

        p {
          font-size: 18px;
          margin-bottom: 15px;
          color: white;
          font-family: 'SF-Pro-Text-Regular';
          a {
            color: #0041E6;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
  
      .connectButton {
        margin-top: 20px;
  
        .connectbutton {
          display: flex;
          width: 230px;
          height: 60px;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 67px;
          background: #0041E6;
          cursor: pointer;
          color: #FFF;
          font-family: 'SF-Pro-Text-Semibold';
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.32px;
          border: none;
  
          // &:hover {
          //   color: white;
          //   box-shadow: 0px 0px 15px rgba(254, 84, 0, 0.7);
          // }
        }
  
        .link {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  
    @media screen and (max-width: 1080px) {
      .container {
        .content {
          h1 {
            font-size: 24px;
          }
          p {
            font-size: 16px;
          }
        }
  
        .connectButton {
          .connectbutton {
            width: 200px;
            height: 50px;
            font-size: 20px;
          }
        }
      }
    }
  
    @media screen and (max-width: 998px) {
      .container {
        .content {
          h1 {
            font-size: 22px;
          }
          p {
            font-size: 14px;
          }
        }
  
        .connectButton {
          .connectbutton {
            width: 180px;
            height: 45px;
            font-size: 18px;
          }
        }
      }
    }
  
    @media screen and (max-width: 660px) {
      .container {
        .content {
          h1 {
            font-size: 20px;
          }
          p {
            font-size: 12px;
          }
        }
  
        .connectButton {
          .connectbutton {
            width: 180px;
            height: 40px;
            font-size: 16px;
          }
        }
      }
    }
  }