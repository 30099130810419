.courseRegistration{
    margin-top: 150px;
    margin-bottom: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width:60%;
        max-width:1170px;
        // gap:40px;
        .maintitle-container{
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            width: 100%;
            margin-bottom: 40px;
            .title{
                margin: 0;
                color: white;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 40px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.5px;
            }
            .validation-message{
                color:red;
                font-family: 'SF-Pro-Text-Regular';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.32px;

            }
        }
        form{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            gap:32px;
            margin-bottom: 20px;

            .form-row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .form-group{
                    display: flex;
                    align-items: start;
                    justify-content: start;
                    flex-direction: column;
                    gap:10px;
                    label{
                        color: #999;
                        font-family: 'SF-Pro-Text-Regular';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.32px;
                        .star{
                            color: #F00;
                            font-family: 'SF-Pro-Text-Regular';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                    input{
                        border:none;
                        display: flex;
                        width: 350px;
                        padding: 12px 24px;
                        align-items: center;
                        gap: 10px;
                        border-radius: 12px;
                        border: 1px solid #FFF;
                        background-color: transparent;
                        color:white;
                        &:focus {
                            outline: none;
                            border: 1px solid #0041E6;
                        }
                        &:hover{
                            color: white; // Ensures text stays white on hover
                            cursor: pointer;    
                            border: 1px solid #0041E6;
                        }
                    }
                    .course{
                        border:none;
                        display: flex;
                        width: 400px;
                        padding: 12px 24px;
                        align-items: center;
                        gap: 10px;
                        border-radius: 12px;
                        border: 1px solid #FFF;
                        background-color: transparent;
                        color:white;
                        appearance: none;
                        option{
                            background-color: transparent;
                            color:white;
                        }
                        &:focus {
                            outline: none;
                            border: 1px solid #0041E6;
                        }
                        &:hover{
                            color: white; // Ensures text stays white on hover
                            cursor: pointer;    
                            border: 1px solid #0041E6;
                        }
                    }
                    .experience{
                        border:none;
                        display: flex;
                        width: 400px;
                        padding: 12px 24px 12px 24px;
                        align-items: center;
                        gap: 10px;
                        border-radius: 12px;
                        border: 1px solid #FFF;
                        background-color: transparent;
                        color:white;
                        appearance: none;
                        &:focus {
                            outline: none;
                            border: 1px solid #0041E6;
                        }
                        &:hover{
                            color: white; // Ensures text stays white on hover
                            cursor: pointer;    
                            border: 1px solid #0041E6;
                        }
                    }

                }

            }

            .proceed-to-pay-button{
                display: flex;
                width: 418px;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #FFF;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.32px;
                border-radius: 12px;
                background: #0041E6;
                border: none;
                cursor: pointer;
                margin-top: 32px;
            }
        }
        .error-message{
            font-family: 'SF-Pro-Text-Regular';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.48px;
            // background: linear-gradient(91deg, #6994FF -0.16%, #0036BE 103.45%);
            background: linear-gradient(90deg, #f54a4a 0%, #BE0000 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

@media (max-width:1350px){
    .courseRegistration{
        .container{
            width:80%;
            .maintitle-container{
                justify-content: center;
                .title{
                    text-align: center;
                }
            }
            form{
                .form-row{
                    flex-direction: column;
                    gap:30px;
                    .form-group{
                        
                    }
                }
            }
        }
    }
}
@media (max-width:998px){
    .courseRegistration{
        .container{
            .maintitle-container{
                justify-content: center;
                .title{
                    text-align: center;
                }
            }
            form{
                .form-row{
                    flex-direction: column;
                    gap:30px;
                    .form-group{
                        
                    }
                }
            }
        }
    }
}
@media (max-width:660px){
    .courseRegistration{
        .container{
            .maintitle-container{
                justify-content: center;
                .title{
                    font-size: 32px;
                    text-align: center;
                }
            }
            form{
                .form-row{
                    flex-direction: column;
                    gap:30px;
                    .form-group{
                                input{
                                    width:270px;
                                }
                                .course{
                                    width: 320px;
                                }
                                .experience{
                                    width: 320px;
                                }
                    }
                }
                .proceed-to-pay-button{
                    width: 300px;
                }
            }
        }
    }
}