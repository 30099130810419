.settings{
    display: flex;
    align-items: start;
    justify-content: start;
    width: 100%;
    .container{
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        width: 40%;
        padding:50px 45px;
        gap:25px;
        .tabs{
            width: 100%;
            border-bottom: 1px solid white;
            display: flex;
            gap:30px;
            padding:15px 0;

            .tab{
                padding: 10px;
                cursor: pointer;
                color:white;
                font-weight: 18px;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.32px;

                &.active{
                    font-weight: bold;
                    color:#0041E6;

                }
            }
        }
        .tab-content{
            .general-settings, .security-settings{
                display: flex;
                flex-direction: column;
                label{
                    margin-bottom: 25px;
                    font-family: 'SF-Pro-Text-Semibold';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.32px;
                    color:white;
                    border: none;

                    input{
                        display: flex;
                        margin-top: 10px;
                        width: 418px;
                        padding: 12px 24px;
                        align-items: center;
                        gap: 10px;
                        border-radius: 12px;
                        border: 1px solid #FFF;
                        // border: none;
                        background-color: 
                        transparent;
                        color: white;

                        // Hover effect for input
                        &:hover {
                            // background-color: #0041E6;
                            color: white; // Ensures text stays white on hover
                            border: 1px solid #0041E6;
                            cursor: pointer;
                        }
    
                        // Keep text color white when input is focused
                        &:focus {
                            color: white;
                            border: 1px solid #0041E6;
                            outline: none; // Remove the default focus outline
                        }
                    }
                }
                button {
                    margin-top: 20px;
                    display: flex;
                    width: 465px;
                    padding: 12px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 12px;
                    background: #0041E6;
                    border: none;
                    color: #FFF;
                    font-family: 'SF-Pro-Text-Semibold';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.32px;
                    cursor: pointer;
                  }
            }
        }
    }
}

@media (max-width:660px){
    .settings{
        .container{
            width: 100%;
            padding:50px 10px;

            .tab-content{
                .general-settings, .security-settings{
                    label{
                        input{
                            width: 280px;
                        }
                    }
                }
            }
        }
    }
}