.user-dashboard {
  width: 100%;

  .NAVBAR{
    z-index: 1600;
  }

  .dashboard-layout {
    display: flex;
    height: 100vh;
    overflow: hidden;
    transition: margin-left 0.6s ease-out;

    .sidebar {
      padding: 0 18px ;
      width: 260px;
      min-height: 100vh;
      flex-shrink: 0;
      z-index: 100;
      // position: sticky;
      top: 0;
      background-color: black; // Your sidebar background color
      transition: transform 0.3s ease-out, width 0.3s ease-out;    
      transform: translateX(0); // Initially visible
    }

    .hidden {
      transform: translateX(-100%); // Move sidebar out of view
      position: absolute;
    }

    .visible {
      transform: translateX(0); // Ensure sidebar is fully visible
    }

    .dashboard-content {
      position: relative;
      z-index: 2; // Ensures content is layered above the sidebar when overlapped
      flex-grow: 1; // Allows content to take up remaining space
      width: calc(100vw - 260px); // Width adjusts based on the sidebar visibility
      min-height: 100vh; // Full viewport height
      background: #0E0E0E; // Set your desired background color
      overflow-y: auto;
      transition: width 0.3s ease-out;

      &::-webkit-scrollbar {
        display: none; // Hide scrollbar for WebKit browsers
      }
      -ms-overflow-style: none; // IE and Edge scrollbar
      scrollbar-width: none; // Hide scrollbar for Firefox

      .sidebar.hidden + & {
        width: 100vw; // Takes full width when sidebar is hidden
      }
    }
  }
}

@media(max-width:1080px){
    .user-dashboard{
      .dashboard-layout{
        min-height: 100vh;
        .sidebar{
          // margin:auto 0;
          position: absolute;
          position: fixed;
          max-width: 260px;
          z-index:101;
          min-height: 100vh;
        }
        .dashboard-content{
          width: 100vw;
          margin-left: 0;
        }
      }
    }
}