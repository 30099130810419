.coursecontent{
    margin-top: 20px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap:25px;
        width: 100%;

        .chaptername{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            .name{
                color:white;
                font-size: 18px;
            }
        }
        .video-container{
          position: relative;
          width: 90%;
          max-width: 1000px;
          display: flex;
          justify-content: center;
          margin-inline: auto;
          background-color: black;
          video {
            width: 100%;
          }
        }
        .buttons{
            width:85%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .resource-button {
              display: inline-block;
              background: #0041E6;
              color: #ffffff;
              padding: 10px 20px;
              text-decoration: none;
              border-radius: 5px;
              font-weight: bold;
              transition: background-color 0.3s ease;
              cursor: pointer;
              font-family: 'SF-Pro-Text-Semibold';
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -0.32px;
        
              &:hover {
                background-color: #0056b3;
              }
            }
        }
    }
}