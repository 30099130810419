.emailPassword {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    .container {
      display: flex;
      align-items: center;
      justify-content: start;
      width:100%;
      max-width: 900px;
      min-height: 500px;
      flex-shrink: 0;
      border-radius: 24px;
      border: 1px solid #303030;
      background: rgba(29, 29, 29, 0.17);
      backdrop-filter: blur(12.5px);    
  
    .emailPassword-left-side {
        min-height: 500px;
        flex: 1; // This will make the left side fill 50% of the .container
        display: flex;
        align-items: start;
        justify-content: start;
        border-radius: 24px 0px 0px 24px;
       
        .image{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
  
    .emailPassword-form {
        flex: 1; // This will make the left side fill 50% of the .container
        height:450px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap:20px;
        // padding: 0 42px;    
          
      .emailPassword-top-section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap:15px;
        flex-direction: column;

        .register-form-header {
            display: flex;
            align-items: start;
            justify-content: start;
                h2{
                    padding: 0 45px; 
                    font-family: 'SF-Pro-Text-Semibold';
                    font-size: 29px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.96px;
                    color:white;
                    margin-bottom: 5px;
                }     
             }
  
        .form {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .button{
                border-radius: 67px;
                background: #0041E6;
                display: flex;
                width: 360px;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #FFF;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.32px;
            }
  

            .MuiTextField-root {
                margin-bottom: 5px;
    
                .MuiInputLabel-filled {
                    // Targets the label of the filled TextField
                    color: #0041E6; // Default label color
                }
    
                .MuiFilledInput-underline:after {
                    // Targets the underline of the TextField after being filled (focused)
                    border-bottom-color: #0041E6 !important; // Changes the underline color to blue
                }
    
                .MuiFilledInput-input {
                    // Targets the input text itself
                    color: white; // Changes the text color to white
                }
    
                .MuiFilledInput-root {
                    // Targets the root of the filled input
                    background-color: rgba(255, 255, 255, 0.09); // Changes the background color
                    
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.13); // Changes background color on hover
                    }
                    &.Mui-focused {
                        background-color: rgba(255, 255, 255, 0.12); // Changes background color when focused
    
                        .MuiInputLabel-filled {
                            color: #0041E6 !important; // Changes the label color to blue when focused
                        }
                    }
                }
            }
            
            .link{
                width: 90%;
                color: #757575;
                font-family: 'SF-Pro-Text-Regular';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: left;
            }
         
        }
      }
      .login-subtitle {
        color: #C9C9C9;
        font-family: 'SF-Pro-Text-Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.36px;
        padding: 0 45px; 
           }
    }
  }
}

@media (max-width:1080px){
    .emailPassword{
        .container{
            max-width : 800px;
        }
    }
}

@media (max-width:900px){
    .emailPassword{
        .container{
            max-width : 600px;
            margin: 0 20px;
            justify-content: center;
            .emailPassword-left-side{
                display: none;
            }
            .emailPassword-form{
                min-height: 380px;
                .emailPassword-top-section{
                    .form{
                        width: 65%;
                    }
                }
                .login-subtitle{
                    width:100%;
                    max-width:380px;
                }
            }
        }

    }
}

@media (max-width:800px){
    .emailPassword{
        .container{
            max-width : 600px;
            margin: 0 20px;
            justify-content: center;
            .emailPassword-left-side{
                display: none;
            }
            .emailPassword-form{
                min-height: 380px;
                .emailPassword-top-section{
                    .form{
                        width: 80%;
                    }
                }
                .login-subtitle{
                    width:100%;
                    max-width:380px;
                }
            }
        }
    }
}

@media (max-width:660px){
    .emailPassword{
        height: 100vh;
        .container{
            max-width : 500px;
            margin: 10px 0;
            justify-content: center;
            .emailPassword-left-side{
                display: none;
            }
            .emailPassword-form{
                min-height: 380px;
                .emailPassword-top-section{
                    .register-form-header{
                        h2{
                            padding: 0;
                        }
                    }
                    .form{
                        width: 90%;
                        .button{
                            width: 300px;
                        }
                    }
                }
                .login-subtitle{
                    width:85%;
                    max-width:380px;
                    padding: 10px;
                }
            }
        }

    }
}