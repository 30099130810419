.faq{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    // margin:0 auto;
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:40px;
        width:100%;
        max-width: 1150px;
        // padding:20px;
        flex-shrink: 0;

        h2{
            text-align: center;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.96px;
            background: linear-gradient(90deg, #FFF 0%, #646464 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            width:100%;
            margin: 0;

        }
        .faqcards-part{
            width: 100%;
        }
    }
}

@media (max-width:700px){
    .faq{
        .container{
            width:95%;
            gap:10px;
            h2{
                font-size: 34px;
            }
        }
    }
}