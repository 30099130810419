.purchases {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // height: 100vh;

    // &.skeleton{
    //   &:before {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     background-image: linear-gradient(
    //       90deg,
    //       rgba(255, 255, 255, 0) 0,
    //       rgba(255, 255, 255, 0.8) 50%,
    //       rgba(255, 255, 255, 0) 100%
    //     );
    //     background-repeat: no-repeat;
    //     background-size: 200% 100%;
    //     animation: shimmer 2s infinite linear;
    //   }
    // }
    
    .container {
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        width: 100%;
        padding:50px 32px;
        gap:50px;
        
      
      .purchase-title-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        
        .maintitle {
          width:100%;
            max-width: 511px;
            color: #FFF;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.96px;
        }
        
        .course-button {
            display: flex;
            width: 133px;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 67px;
            background: #0041E6;
            border:none;
            .link{
                color: #FFF;
                font-family: 'SF-Pro-Text-Semibold';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.32px;
            }
        }
      }
      
      .purchased-courses {
        width: 100%;
        display: flex; // If you have multiple cards, you want to align them nicely.
        flex-wrap: wrap;
        gap:24px;

        .no-courses-message{
          display: flex;
          align-items: center;
          justify-content: center;
          color: #C9C9C9;
          font-family: 'SF-Pro-Text-Regular';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.32px;

          .browse-link{
            margin-left: 5px;
            text-decoration: underline;
          }
        }
        
      }
    }
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }

  
  @media (max-width:998px){
    .purchases{
      .container{
        .purchased-courses{
          justify-content: center;
        }
      }
    }
  }
  @media (max-width:660px){
    .purchases{
      .container{
        .purchase-title-button{
          margin:0 20px;

          .maintitle{
            font-size: 42px;
          }
          .course-button{
            display: none;
          }
        }
        .purchased-courses{
          justify-content: center;
        }
      }
    }
  }