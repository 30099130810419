.footer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    width:100%;

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 1512px;
        padding: 22px 170px;

        .footer-main-links{
            width:100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            border-bottom: 1px solid #666;

            .footer-main-links-left{
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                gap:24px;
                margin-bottom: 24px;

                .footer-logo-container{
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: 120px;
                    height: 40px;
                    flex-shrink: 0;

                
                    .footer-logo{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        margin-left:-25px;
                        margin-top: -25px;
                    }
                }

                .social-links{
                    display: flex;
                    align-items: start;
                    justify-content: start;
                    gap:32px;
                    cursor: pointer;
                    .social-links-image{
                        width: 26px;
                        height: 26px;
                        flex-shrink: 0;
                    }
                }
            }

            .footer-main-links-right{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                gap:54px;

                .left-points{
                    color: #C9C9C9;
// font-family: "SF Pro Display";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.36px;
                    text-decoration: none;
                }
            }
            .social-links-for-660px{
                display: none;
                align-items: start;
                justify-content: start;
                gap:32px;
                cursor: pointer;

                    .social-links-image{
                        width: 26px;
                        height: 26px;
                        flex-shrink: 0;
                    }
            }
        }
        .footer-bottom-links{
            margin-top: 24px;
            width:100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .footer-bottom-links-left{
                display: flex;
                justify-content: center; // Centers the content horizontally
                align-items: center; // Centers the content vertically
                text-align: center;
                .footer-bottom-links-left-text{
                    color: #C9C9C9;
                    // font-family: "SF Pro Display";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.28px;
                    text-align: center;
                }
            }
            .footer-bottom-links-right{
                display: flex;
                justify-content: center; // Centers the content horizontally
                align-items: center; // Centers the content vertically
                gap:40px;

                .right-points{
                    color: #C9C9C9;
                    // font-family: "SF Pro Display";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.28px;
                    text-decoration: none;
                }
            }
        }
    }
}

@media (max-width:1080px) {
    .footer{
        .container{
            padding: 22px 40px;

            // .footer-main-links{
            //     display: flex;
            //     flex-direction: column;
            //     padding:24px 0;
            //     .footer-main-links-left{
            //         width: 100%;
            //         display: flex;
            //         flex-direction: row;
            //         align-items: center;
            //         justify-content: space-between;
            //     }
            //     .footer-main-links-right{
            //         width: 100%;
            //         align-items: center;
            //         justify-content: center;
            //         .left-points{
            //             font-size: 16px;
            //         }
            //     }
            // }
            // .footer-bottom-links{
            //     display: flex;
            //     flex-direction: column;
            //     gap:15px;
            // }
        }
    }
}

@media (max-width:700px){
.footer{
    .container{

        .footer-main-links{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            gap:24px;

            .footer-main-links-left{
                .footer-logo-container{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                   .link{
                        display: flex;
                        align-items: center;
                        justify-content: center;     
                    .footer-logo{
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;     
                    }
                   }
                }
                .social-links{
                    display: none;
                }
            }
            .footer-main-links-right{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap:24px;
            }
            .social-links-for-660px{
                margin-bottom: 20px;
                display: flex;
                cursor: pointer;

            }
        }
        .footer-bottom-links{
            gap:24px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column-reverse;
            .footer-bottom-links-left{

            }
            .footer-bottom-links-right{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 24px;
            }
        }
    }
}
}