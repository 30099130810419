#one-tap-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999; /* Ensure this is the highest z-index on your page */
  }
  
.navbar{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    z-index: 99999;
    transition: 0.5s all ease;
    box-shadow: 0px 4px 33.5px 0px rgba(0, 0, 0, 0.25);
    background-color: #000;
    min-height:70px;
    width:100%;

    .container{
        width: 100%;
        max-width: 1512px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 171px;

        .logo-container{
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
                
            .logo{
                // padding:20px;
                // margin-top: 18px;
                margin-left: -25px;
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                object-fit: cover;
                width: 120px;
                height: 40px;
                flex-shrink: 0;
            }
        }

        .menu-icon{
            display: none;
            .menu-button{
                background: none;
                border: none;
                cursor: pointer;
                color: white;
                font-size: 20px;
            }
        }

        .navlinks{
            display: flex;
            align-items: center;
            justify-content: space-between;
            color:white;
            gap:60px;

            .nav-links{
                display: flex;
                align-items: center;
                justify-content: center;
                ul{
                    list-style: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap:40px;
                    li{
                        .link{  
                            display: inline-flex;
                            padding: 8px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            color: #FFF;
                            font-family: 'SF-Pro-Text-Regular';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.32px;
                            transition: background 0.5s ease;


                            &:hover{
                                border-radius: 8px;
                                background: #1D1D1D;
                            }

                            &.active{
                                border-radius: 8px;
                                background: #1D1D1D;                  
                            }
                        }

                        .testimonials{
                            &:hover{
                                border-radius: 8px;
                                background: #1D1D1D;
                            }
                            &.active-testimonials{
                                border-radius: 8px;
                                background: #1D1D1D;
                                transition: background 0.5s ease;
                            }
                        }
                        
                    }
                }

            }
            .nav-buttons{
                display: flex;
                align-items: center;
                justify-content: center;
                gap:8px;

                .logout-button{
                    width: 120px;
                    height:43px;
                    color: #FFF;
                    font-family: 'SF-Pro-Text-Semibold';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.32px;
                    display: inline-flex;
                    padding: 12px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 67px;
                    background: #0041E6;
                    padding: 10px 24px;
                    cursor: pointer;
                    border:none;
                }

                .button-link{
                    display: flex;
                    width: 90px;
                    padding: 10px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 67px;
                    text-decoration: none;
                    cursor: pointer;

                    
                }

                .login-button{
                    border: 1px solid #fff;
                    // background-color: #fff;
                    transition: background-color 0.5s ease, color 0.5s ease;
                    

                    &:hover{
                        background-color: #0041E6;
                        border: 1px solid #0041E6;

                        button{
                            color: #fff;
                        }
                    }

                    
                    
                }

                .register-button{
                    background: #0041E6;
                    border: 1px solid #0041E6;
                    transition: background-color 0.5s ease, color 0.5s ease;

                    &:hover{
                        background-color:#000;
                        border: 1px solid #fff;

                        button{
                            color:#fff;
                        }
                    }
                }
                .button{
                    border:none;
                    background: transparent;
                    color: #FFF;
                    font-family: 'SF-Pro-Text-Semibold';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.32px;
                    text-decoration: none;
                    cursor: pointer;
                }
                
            }
        }


    }
}

@media (max-width:1300px){
    .navbar{
        .container{
            padding:15px 120px;
            .navlinks{
                gap:40px;
                .nav-links{
                    ul{
                        gap:40px;
                    }
                }
                .nav-buttons{
                    .button-link{
                        width: 80px;
                    }
                }
            }
        }
    }
}

@media (max-width:1080px){
    .navbar{
        .container{
            padding: 22px 40px;
            .menu-icon{
                display: block;
                transition: transform 0.3s ease, color 0.3s ease;

                cursor: pointer;
                .menu-button{
                    display: block;
                    transition: transform 0.3s ease, color 0.3s ease;
                }
            }
            .navlinks {
                display: none; // Hidden by default
              
                &.active { // Displayed when 'active' class is applied
                  padding:15px 0;
                  display: flex;
                  flex-direction: column;
                  position: absolute;
                  top: 70px; // Adjust based on your navbar's height
                  left: 0;
                  width: 100%;
                  background-color: #000; // Changed to match the navbar color
                  z-index: 101;
                  gap:20px;
                  border: 1px solid #303030;
                  background: rgba(29, 29, 29, 0.17);
                  backdrop-filter: blur(12.5px);
              
                  .nav-links ul {
                    flex-direction: column;
                    margin: 0;
                    padding: 0;
                    gap:15px;
                    
              
                    li {
                      .link {
                        display: block; // Full-width links for tap targets on mobile
                        padding: 8px 16px; // More padding for tap targets
                        position: relative; // Needed for absolute positioning of pseudo-elements
              
                        &:hover::after {
                          content: "";
                          position: absolute;
                          bottom: 0; // Position at the bottom of the link
                          left: 0;
                          width: 100%;
                          height: 2px;
                        //   background-color: #0041E6;
                        }
                      }
                    }
                  }

                  .nav-buttons{
                    flex-direction: column;
                    gap:18px;
                  }
                }
              }
        }
    }
}