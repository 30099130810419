.curriculum-card {
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 1250px;
    min-height: 80px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid #303030;
    background: rgba(29, 29, 29, 0.48);
    backdrop-filter: blur(12.5px);
    margin:12px 0;

    .module-info {
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
    //   padding:24px;
      padding:24px 34px 24px 24px;

      .leftside-curriculumcard {
        display: flex;
        align-items: center;
        justify-content: center;
        gap:15px;        
  
        .toggle-button {
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            // margin-right: 10px; // Example for space between the arrow and the title
          img {
            width: 100%;
            height:100%;
            object-fit: cover;
          }
        }
          .title {
            text-align: left;
            margin:0;
            color: #FFF;
            font-family: 'SF-Pro-Text-Medium';
            font-size: 21px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.48px;
          }
      }
  
      .right-side-curriculumcard {
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 160px;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #272727;
        .module-no {
            color: #FFF;
            font-family: 'SF-Pro-Text-Regular';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
        }
      }
    }
  
    .module-contents {
        border-top:1px solid #303030;
        box-sizing: border-box;
        display: none; // This should start as hidden and toggle based on the isOpen state
        align-items: center;
        justify-content: center;
        border-radius: 0 0 16px 16px;
        border: 1px solid #303030;
        background: rgba(29, 29, 29, 0.48);
        backdrop-filter: blur(12.5px);
        width: 100%;
        padding: 24px 35px 24px 35px;
        border-top:1px solid #303030;
        overflow: hidden;
        transition: max-height 1s ease-in-out;

      &.open {
        display: block; // Shown when the module is open
        max-height: 1000px; // A large enough value to accommodate the content
        padding: 24px 35px; // Apply padding when opened
        visibility: visible;
      }
  
      ul {
        list-style-type: none; // Remove default list bullets
        padding: 0; // Remove default padding
        // gap:24px;
  
        li {
          display: flex;
          align-items: start;
          justify-content: start;
          margin-bottom: 24px; // Space between list items
          gap:40px;
  
          .lesson-no {
            font-weight: bold;
            margin-right: 5px; // Space between lesson number and name
            color: #C9C9C9;
            font-family: 'SF-Pro-Text-Regular';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
          }
  
          .lesson-name {
            color: #C9C9C9;
            font-family: 'SF-Pro-Text-Regular';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
          }
        }
      }
    }
  }
  
  @media (max-width:700px){
    .curriculum-card{
      .module-info{
        // flex-direction: column-reverse;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap:10px;
      }
    }
  }