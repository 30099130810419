.companies{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2{
            width: 100%;
            text-align: center;
            text-align: center;
            font-family: 'SF-Pro-Text-Semibold';
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.96px;
            background: linear-gradient(90deg, #FFF 0%, #646464 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .company-images{
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;                
            flex-wrap: wrap; 
            max-width: 1512px;
            width:100%;
            gap:90px;
            // padding:0 20px;

            img{
                width: 140px;
                height: 48px;
                flex-shrink: 0;
            }
        }
    }
}

@media (max-width:1080px){
    .companies{
        .container{
            .company-images{
                gap:60px;
            }
        }
    }
}


@media (max-width:998px){
    .companies{
        .container{
            .company-images{
                gap:30px;
            }
        }
    }
}


@media (max-width:660px){
    .companies{
        .container{
            h2{
                font-size: 36px;
            }
        }
    }
}